<template>
  <b-modal id="popup-coating-types" ref="popup-coating-types" centered="centered" :title="(coatingType.id ? 'Modifier': 'Ajouter') + ` un type de revêtement`" @ok="create">
      <div v-if="isCreatingCoatingType">
          <div class="text-center flex-center">
            <div class="loading-bg-inner">
              <div class="loader">
                <div class="outer"></div>
                <div class="middle"></div>
                <div class="inner"></div>
              </div>
            </div>
            <div class="mt-5">
              <br /><br /><br />
              Chargement...
            </div>
          </div>
      </div>
      <validation-observer v-else ref="formCoatingType">
          <b-row>
              <b-col cols="12" class="d-flex mb-1">
                  <b-form-group label="Libellé *" label-for="Libellé" style="flex:1">
                      <validation-provider #default="{ errors }" name="Libellé" rules="required">
                      <b-form-input
                          id="label"
                          v-model="coatingType.label"
                          :state="errors.length > 0 ? false : null"
                          class="not-autocomplete"
                          autocomplete="nope" aria-autocomplete="nope"
                          v-on:keyup.enter="create"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                  </b-form-group>
              </b-col>
          </b-row>
      </validation-observer>
      <template #modal-footer="{ ok, cancel }">
          <div class="w-100 mx-0">
              <b-button class="float-left" v-if="coatingType.id" variant="outline-danger" @click="deleteInterventionTypeLocal(coatingType.id, coatingType.label)"><feather-icon icon="Trash2Icon" /></b-button>
              <b-button class="float-right" variant="primary" @click="ok()">{{coatingType.id ? 'Modifier': 'Ajouter'}}</b-button>
              <b-button class="float-right mr-1" variant="outline-primary" @click="cancel()">Annuler</b-button>
          </div>
      </template>
  </b-modal>
  </template>
  
  <script>
  import {
    ValidationProvider,
    ValidationObserver,
    configure,
    localize,
  } from "vee-validate";
  import { required, url, email } from "@validations";
  import { mapGetters, mapActions } from "vuex";
  import Ripple from "vue-ripple-directive";
  import "vue-swatches/dist/vue-swatches.css";
  configure({
    // Generates an English message locale generator
    generateMessage: localize("fr", {
      messages: {
        url: "Ce champ doit être une URL",
        required: "Ce champ est requis",
        email: "Ce champ doit être un email",
      },
    }),
  });
  localize("fr");
  
  export default {
    data() {
      return {
        required,
        url,
        email,
        coatingType: {
          label: "",
          workspaceId: this.$store.getters.workspaceSelected.id,
        },
      };
    },
    methods: {
      popupCreateCoatingTypesEvent(id){
        if(id>0){
          this.fetchCoatingType(id).then(res => {
            this.coatingType = res
          })
        }else this.initializeForm()
      },
      initializeForm() {
        this.coatingType = {
          label: "",
          workspaceId: this.$store.getters.workspaceSelected.id,
        };
      },
      deleteInterventionTypeLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm("Cette action est irréversible.", {
          title:
            `Êtes-vous sûr de vouloir supprimer le type de revêtement ${label} ?`,
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveCoatingType(id);
            this.$nextTick(() => {
              this.$bvModal.hide('popup-coating-types')
            })
          }
          
        })
    },
      create(bvModalEvt) {
        bvModalEvt.preventDefault();
        this.$refs.formCoatingType.validate().then((success1) => {
          if (success1) {
            if (this.coatingType.id) this.updateCoatingType(this.coatingType)
            else this.createCoatingType(this.coatingType);
            this.$nextTick(() => {
              this.$refs["popup-coating-types"].toggle("#toggle-btn");
            });
          }
        });
      },
      ...mapActions(["createCoatingType", "updateCoatingType", "fetchCoatingType", "archiveCoatingType"]),
    },
    computed: {
      ...mapGetters(["isCreatingCoatingType"]),
    },
    
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    directives: {
      Ripple,
    },
  };
  </script>